.projects-logo {
  opacity: 0;
  width: 20px;
  height: 32px;
  margin-left: 20px;
  background-image: url(../../images/Projects/logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.projects-user-info-wrapper {
  position: relative;
  display: flex;
  width: 100px;
  height: 32px;
}

.projects-user-info {
  display: flex;
  align-items: center;
  height: 32px;
}

.projects-user-info-default-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #ededed;
  border-radius: 50%;
  cursor: pointer;

  &:hover .projects__avatar path {
    fill: #333;
  }

  &.active {
    background: #f5f5f5;

    & .projects__avatar path {
      fill: #333;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #f5f5f5;
    }
  }
}

.projects__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
}

.projects-user-info-user-name {
  margin-right: 12px;
  color: #333;
  font-weight: 600;
  font-size: 10px;
  font-style: normal;
  line-height: 20px;
}

.projects-container {
  width: 800px;
  margin: 0 auto 64px auto;
}

.projects-top-row {
  width: 100%;
  height: 56px;
}

.projects-title {
  position: absolute;
  top: 4px;
  left: 0;
  color: #333;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.projects-create-project-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 127px;
  height: 32px;
}

.projects-search {
  width: 100%;
  height: 32px;
  margin-bottom: 32px;
}

.project-row {
  position: relative;
  width: 100%;
  height: 88px;
  border: 1px solid #ededed;
  border-radius: 4px;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 16px;
  }

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  }
}

.project-row-name {
  position: absolute;
  top: 24px;
  left: 16px;
  color: #333;
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
}

.project-row-date {
  position: absolute;
  top: 48px;
  left: 16px;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 16px;
}

.projects-control-panel {
  width: 100%;
  height: 16px;
  margin: 24px 0 16px 0;
}

.projects-control-panel-btn {
  float: left;
  color: #333;
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;

  &.starred {
    margin-left: 16px;
  }

  &.disable {
    color: #aaa;
  }
}

.projects-control-panel-icon-btn {
  float: right;
  width: 16px;
  height: 16px;

  &.list-view-icon-btn {
    background-image: url(../../images/Projects/list-view-icon.svg);
  }

  &.grid-view-icon-btn {
    margin-left: 16px;
    background-image: url(../../images/Projects/grid-view-icon.svg);
  }
}

.project-row__points-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.project-row__menu-button {
  position: absolute;
  top: 50%;
  right: 24px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transform: translateY(-50%);

  &:hover svg path {
    fill: #666;
  }

  &.active {
    background: #f5f5f5;

    & svg path {
      fill: #333;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #f5f5f5;
    }
  }
}

.project-row__menu-list {
  top: 60px;
  right: 24px;
  box-sizing: border-box;
  width: 158px;
  margin-top: 1px;
  padding: 7px 0;
  border: 1px solid #ededed;
  border-radius: 3px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
}

.projects__logout-menu {
  width: 151px;
  height: 32px;
  border: 1px solid #ededed;
  border-radius: 3px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
}

.projects__user-menu-list {
  top: 36px;
  right: 16px;
  width: 158px;
  margin-top: 0;
  padding: 7px 0;
  border: 1px solid #ededed;
  border-radius: 3px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
}

.projects__no-projects-message {
  color: #333;
  font-weight: normal;
  font-size: 11px;
  font-style: normal;
  line-height: 14px;
}
